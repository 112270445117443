import "./styles/globals.css"

import { useState } from "react"
import { useTheme } from "@/routes/utils+/theme-switch"
import { ClerkApp } from "@clerk/remix"
import { rootAuthLoader } from "@clerk/remix/ssr.server"
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node"
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
} from "@remix-run/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { NuqsAdapter } from "nuqs/adapters/remix"

import { ClientHintCheck, getHints } from "@/lib/utils/client-hints"
import type { Theme } from "@/lib/utils/theme-cookie.server"
import { getTheme } from "@/lib/utils/theme-cookie.server"
import { TailwindIndicator } from "@/components/global/tailwind-indicator"

import { Toaster } from "./components/ui/toast"
import { cn } from "./lib/utils/classnames"
import { getEnv } from "./lib/utils/env.server"

export const links: LinksFunction = () => [
	{ rel: "preconnect", href: "https://fonts.googleapis.com" },
	{
		rel: "preconnect",
		href: "https://fonts.gstatic.com",
		crossOrigin: "anonymous",
	},
	{
		rel: "stylesheet",
		href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
	},
	{
		rel: "stylesheet",
		href: "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css",
	},
]

interface DocumentProps {
	children: React.ReactNode
	theme?: Theme
	locale: string
}

function Document({ children, theme = "light", locale }: DocumentProps) {
	const data = useLoaderData<typeof loader>()

	return (
		<html lang={locale} className={cn(theme, "h-full overflow-x-hidden")}>
			<head>
				<ClientHintCheck />
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1"
				/>
				<Meta />
				<Links />
			</head>
			<body
				className={cn(
					"relative overflow-hidden",
					// light mode gradient
					"bg-[linear-gradient(170deg,_hsl(var(--brake-700)_/_7%)_24.57%,_hsl(var(--brake-500)_/_11%)_49.43%,_hsl(var(--fiveo-500)_/_14%)_93.36%,_hsl(var(--fiveo-700)_/_19%)_102.63%)]",
					// dark mode gradient
					"dark:bg-[linear-gradient(170deg,_hsl(var(--brake-500)_/_11%)_24.57%,_hsl(var(--brake-400)_/_14%)_49.43%,_hsl(var(--fiveo-400)_/_15%)_93.36%,_hsl(var(--fiveo-500)_/_21%)_102.63%)]",
				)}
			>
				{children}
				<TailwindIndicator />
				<Toaster />
				<ScrollRestoration />
				<script
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(data.ENV)}`,
					}}
				/>
				<Scripts />
				<script
					src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
					integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
					crossOrigin=""
				/>
			</body>
		</html>
	)
}

export const loader = async (args: LoaderFunctionArgs) => {
	const theme = await getTheme(args.request)
	return rootAuthLoader(args, ({ request }) => {
		return {
			requestInfo: {
				hints: getHints(request),
				path: new URL(request.url).pathname,
				userPrefs: { theme },
			},
			ENV: getEnv(),
		}
	})
}

// export const ErrorBoundary = () => {
// 	const error = useRouteError()
// 	captureRemixErrorBoundaryError(error)
// 	return <div>Something went wrong</div>
// }

function App() {
	const theme = useTheme()
	const [queryClient] = useState(() => new QueryClient())

	return (
		<Document theme={theme} locale="en">
			<QueryClientProvider client={queryClient}>
				<NuqsAdapter>
					<Outlet />
				</NuqsAdapter>
			</QueryClientProvider>
		</Document>
	)
}

export default ClerkApp(App)
